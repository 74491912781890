<template>
  <dropdown-button buttonClass="main bordered">
    <template #buttonContent>
      <i :class="[getIcon(), 'mr-1']"></i>
      {{ getLabel() }} <i class="fas fa-chevron-down ml-1"></i>
    </template>
    <div class="card">
      <fd-list-item
        v-if="!isMatchedRoute(bankLoanRoute)"
        @click="$router.push(bankLoanRoute)"
        ><i class="fas fa-dollar-sign mr-1"></i> Active Bank Loan
      </fd-list-item>
      <fd-list-item
        v-if="!isMatchedRoute(bankLoanHistoryRoute)"
        @click="$router.push(bankLoanHistoryRoute)"
      >
        <i class="fas fa-history mr-1"></i> Bank Loan History
      </fd-list-item>
      <fd-list-item
        v-if="!isMatchedRoute(otherRoute)"
        @click="$router.push(otherRoute)"
        ><i class="fas fa-ellipsis-h mr-1"></i> Other Methods
      </fd-list-item>
    </div>
  </dropdown-button>
</template>

<script>
import DropdownButton from "@/components/GlobalComponents/DropdownButton";
import FdListItem from "@/components/GlobalComponents/List/FdListItem";

export default {
  components: {
    DropdownButton,
    FdListItem
  },
  mixins: [],
  props: {
    bankLoanRoute: {
      type: Object,
      default: () => ({ name: "ManageProjectMortgageBankLoan" })
    },
    bankLoanHistoryRoute: {
      type: Object,
      default: () => ({ name: "ManageProjectMortgageBankLoanHistory" })
    },
    otherRoute: {
      type: Object,
      default: () => ({ name: "ManageProjectMortgageOther" })
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    isMatchedRoute(route) {
      return (
        this.$route.name === route.name ||
        this.$route.path === route.path ||
        this.$route.matched.filter(
          (matchedRoute) =>
            matchedRoute.name == route.name || matchedRoute.path == route.path
        ).length > 0
      );
    },
    getLabel() {
      return this.isMatchedRoute(this.bankLoanRoute)
        ? "Active Bank Loan"
        : this.isMatchedRoute(this.bankLoanHistoryRoute)
        ? "Bank Loan History"
        : this.isMatchedRoute(this.otherRoute)
        ? "Other Methods"
        : "";
    },
    getIcon() {
      return this.isMatchedRoute(this.bankLoanRoute)
        ? "fas fa-dollar-sign"
        : this.isMatchedRoute(this.bankLoanHistoryRoute)
        ? "fas fa-history"
        : this.isMatchedRoute(this.otherRoute)
        ? "fas fa-ellipsis-h"
        : "";
    }
  }
};
</script>

<style lang="scss"></style>
